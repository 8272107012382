.faq-section {
    background: linear-gradient(135deg, #1f1f7a, #1e90ff);
    color: white;
    padding: 40px 20px;
    border-radius: 10px;
    margin: 20px auto;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative; /* Ensures it sits on top of the background */
    z-index: 10; /* Places FAQ above any overlapping background elements */
}

.faq-section h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

.faq-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.faq-item {
    border: 1px solid #ffffff55;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.faq-question {
    background-color: #ffffff22;
    padding: 15px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.faq-question:hover {
    background-color: #ffffff33;
}

.faq-question.active {
    background-color: #1e90ff;
    color: white;
}

.faq-answer {
    background-color: #ffffff11;
    padding: 15px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffffcc;
}

/* Adjust the background layers */
.starry-background,
.nebula-background {
    position: relative;
    z-index: 0; /* Ensures background stays behind content */
    padding: 60px 20px;
}