body {
  margin: 0;
  font-family: 'Montserrat', 'Arial', sans-serif;
  color: #f1f1f1;
  background: linear-gradient(to bottom, #18334f, #0f2438); /* Darker blue gradient */
}

.App {
  text-align: center;
  padding: 0;
  overflow-x: hidden;
}

section {
  padding: 60px 20px;
  border-radius: 15px;
  margin: 10px;
  background: #162b3c; /* Darker section background */
  color: #e3e8ed;
  border: 2px solid #1da1f2;
}

.hero {
  background: linear-gradient(to right, #154473, #1284a3); /* Darker blue-green gradient */
  color: #fff;
  border-radius: 0 0 30px 30px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.hero p {
  font-size: 1.5rem;
}

.hero button {
  padding: 12px 25px;
  background: #fff;
  color: #154473;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.hero button:hover {
  background: #d6faff;
  color: #1284a3;
  transform: scale(1.1);
}
.about img {
  max-width: 25%; /* Reduced size to 25% for a 50% smaller display */
  height: auto;
  border-radius: 15px;
  margin: 20px auto;
  display: block;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}

.about-text {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about, .tokenomics, .roadmap, .community {
  background: #1d3b50; /* Dark slate background */
  color: #cdd7e3;
  border-radius: 15px;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #1db954; /* Green to emphasize titles */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

p, ul, ol {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #e3e8ed; /* Softer light color for text */
}

ul, ol {
  list-style: none;
  padding: 0;
}

ul li, ol li {
  margin: 10px 0;
  padding: 5px 10px;
  background: #224d67; /* Muted blue highlight */
  border-radius: 5px;
  color: #e3e8ed;
  transition: transform 0.2s ease, background 0.3s ease;
}

ul li:hover, ol li:hover {
  transform: scale(1.05);
  background: #2a6584; /* Slightly brighter on hover */
}

a {
  color: #1db954; /* Green link color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
  color: #1284a3; /* Light blue on hover */
}

button {
  font-family: 'Comic Sans MS', 'Arial', sans-serif;
}

.loading-bar {
  background: #1db954; /* Green progress bar */
}

.progress {
  background: #1284a3; /* Darker blue progress */
}

.image-container img {
  border: 5px solid #1284a3;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
/* General Starry Background */
@keyframes twinkle {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

.starry-background {
  background: radial-gradient(ellipse at bottom, #001f33, #000);
  position: relative;
  overflow: hidden;
}

.starry-background::before, .starry-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1;
}

.starry-background::before {
  background: transparent;
  background-image: radial-gradient(circle, #ffffff 2px, transparent 2px),
                    radial-gradient(circle, #1da1f2 1px, transparent 1px),
                    radial-gradient(circle, #1db954 1px, transparent 1px);
  background-size: 50px 50px, 75px 75px, 100px 100px;
  background-position: 0 0, 50px 50px, 25px 25px;
  animation: twinkle 3s infinite;
}

.starry-background::after {
  background: linear-gradient(120deg, rgba(0, 183, 255, 0.1), transparent, rgba(0, 183, 255, 0.1));
  mix-blend-mode: screen;
}

/* Nebula Background Animation */
@keyframes nebula {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.nebula-background {
  background-image: linear-gradient(120deg, rgba(255, 0, 150, 0.1), transparent, rgba(0, 183, 255, 0.3));
  background-size: 200% 200%;
  animation: nebula 10s linear infinite;
}
.circular-image {
  width: 300px; /* Adjust size as needed */
  height: 300px;
  border-radius: 100%;
  border: 3px solid #fff; /* Optional: Add a border */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Optional: Add a shadow */
  margin: 20px auto; /* Center the image */
  display: block;
}
.hero {
  background: linear-gradient(to right, #1da1f2, #1db954); /* Background gradient */
  color: #fff;
  border-radius: 0 0 30px 30px;
  position: relative; /* Add position relative */
  z-index: 1; /* Ensure the hero section is above other elements */
  padding: 20px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  position: relative; /* Ensure button group is above the background */
  z-index: 2; /* Ensure buttons are on top of the background */
}

.active-button {
  padding: 10px 20px;
  background: #1db954; /* Green for active buttons */
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 3; /* Ensure the buttons themselves are above other content */
}

.active-button:hover {
  background: #148a3b; /* Darker green on hover */
  transform: scale(1.05);
}

.greyed-out button {
  padding: 10px 20px;
  background: #a9a9a9; /* Grey for inactive buttons */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: not-allowed;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 3; /* Ensure these are also above other content */
}
