.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1a1a1a;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-bar {
    width: 80%;
    height: 10px;
    background: #333;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .progress {
    height: 100%;
    background: linear-gradient(to right, #ff416c, #ff4b2b);
    transition: width 0.3s ease;
  }
  
  .image-container {
    width: 600px; /* Increased size */
    height: 600px; /* Increased size */
    margin: 20px 0;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .fade-in {
    animation: fadeIn 0.3s ease-in;
  }
  
  .fade-out {
    animation: fadeOut 0.3s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }